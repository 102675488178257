var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "section",
    [
      _vm.items && _vm.items.length > 0
        ? _c(
            "div",
            _vm._l(_vm.items, function (item, index) {
              return _c(
                "v-badge",
                {
                  key: index,
                  staticClass: "d-block my-4",
                  attrs: {
                    dot: "",
                    bordered: "",
                    "offset-x": "6",
                    "offset-y": "6",
                    content: !item.isRead,
                    value: !item.isRead,
                  },
                },
                [
                  _c(
                    "v-card",
                    {
                      staticClass: "border rounded-lg px-2 py-4",
                      attrs: { hover: "", ripple: "", elevation: "0" },
                    },
                    [
                      _c("span", { staticClass: "d-block f12 grey--text" }, [
                        _vm._v(
                          _vm._s(
                            _vm._f("moment")(
                              item.createDate,
                              "dddd , jD jMMMM jYYYY"
                            )
                          )
                        ),
                      ]),
                      _c(
                        "span",
                        { staticClass: "d-block f15 font-weight-bold" },
                        [_vm._v(_vm._s(item.title))]
                      ),
                      _c("span", { staticClass: "d-block f14" }, [
                        _vm._v(_vm._s(item.message)),
                      ]),
                    ]
                  ),
                ],
                1
              )
            }),
            1
          )
        : _c("span", { staticClass: "text-center f16 d-block mt-8" }, [
            _vm._v(" " + _vm._s(_vm.$t("No results found")) + " "),
          ]),
      _c("infinite-loading", { on: { infinite: _vm.infiniteHandler } }, [
        _c(
          "div",
          { attrs: { slot: "spinner" }, slot: "spinner" },
          [_c("v-icon", [_vm._v("mdi-selection-ellipse spin")])],
          1
        ),
        _c(
          "div",
          { attrs: { slot: "no-more" }, slot: "no-more" },
          [_c("v-icon", [_vm._v("mdi-access-point-off")])],
          1
        ),
        _c(
          "div",
          { attrs: { slot: "no-results" }, slot: "no-results" },
          [_c("v-icon", [_vm._v("mdi-access-point-off")])],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }